<template>
  <div>
    <!-- Crear input con boton para realizar busqueda -->
    <w-top-bar>Pruebas</w-top-bar>
    <div class="inline-block min-w-full py-2 align-middle px-4">
      <div class="flex">
        <input
          type="text"
          v-model="sku"
          class="p-2 border border-gray-300 rounded-l"
        />
        <button
          @click="find({ query: { sku } })"
          class="px-4 py-2 bg-blue-500 text-white rounded-r"
        >
          Buscar
        </button>
      </div>

      <div class="mt-4">
        <table class="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th
                class="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Count
              </th>
              <th
                class="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ID
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white">
              <td class="px-6 py-4 whitespace-nowrap">
                {{ resultados[0].count }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ resultados[0]._id }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-4">
        <code class="p-2 bg-gray-200">{{ resultados }}</code>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      sku: "OR9",
      resultados: [],
    };
  },
  methods: {
    ...mapActions("prelanzamientos", ["find"]),
  },
  async created() {
    // Obtener los prelanzamientos de un sku utilizando mapActions
    this.resultados = await this.find({ query: { sku: this.sku } });
    console.log("resultados", this.resultados);

    // Obtener los prelanzamientos de un sku utilizando el servicio
  },
};
</script>

<style lang="scss" scoped>
</style>